class FloorPlanCommentModel {
    static postPayload(e) {
        return {
            content: e.content || "",
            projectUnitFloorPlanId: e.projectUnitFloorPlanId || ""
        };
    }

    static getToEditResponse(e) {
        return {
            id: e.id || "",
            content: e.content || "",
            projectUnitFloorPlanId: e.projectUnitFloorPlan.id || ""
        };
    }
}

export default FloorPlanCommentModel;
