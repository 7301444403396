<template>
  <div class="edit-fp-comment">
    <fd-form class="p-2" @submit="submitForm">
      <fd-form-section title="Edit Comment">
        <fd-textarea
          v-model="commentForm.content"
          class="col-12"
          placeholder="Write a comment"
          name="floorPlanComment"
          :rows="5"
          autoResize
          :validators="[validator.required]"
        ></fd-textarea>
      </fd-form-section>

      <div class="col-12 d-flex justify-content-end">
        <button type="button" class="btn mt-1" @click="$emit('cancel')">
          Cancel
        </button>
        <button class="btn main mt-1 ml-1">Update</button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import FloorPlanCommentModel from "@/models/floorPlanComment";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  name: "EditFloorPlanComment",
  components: {},
  mixins: [],
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      commentForm: {},

      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.commentForm = FloorPlanCommentModel.getToEditResponse(this.comment);
  },
  methods: {
    async submitForm() {
      this.$emit(
        "submit",
        this.commentForm.id,
        FloorPlanCommentModel.postPayload(this.commentForm)
      );
    }
  }
};
</script>

<style lang="scss">
.edit-fp-comment {
}
</style>
